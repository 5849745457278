import React from 'react'; 
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const About = () => {
	
	return(
		<>
			<Helmet>
				<title>About Inspired Canine Grooming | Mobile Dog Groomers in Fareham</title>
				<meta 
					name="description" 
					content="Meet the team at Inspired Canine Grooming, your trusted mobile dog groomers in Fareham. Learn about our experience, passion for pups, and commitment to quality care." 
				/>
			</Helmet>
			<motion.section
				whileInView={{ y: 0, opacity: 1, scale: 1 }}
				initial={{ y: 50, opacity: 0, scale: 0.5 }}
				transition={{ duration: 0.4 }}
				id="about"
				className="about">
				<div className="centralCont aboutWrap">
					<div className="info"> 
						<h2>About Us</h2>
						<p>We are passionate about providing the best possible care for your furry friends.</p>
						<br/>
						<p>Say goodbye to the hassle of salon visits! Our mobile dog grooming service brings the spa directly to your home. Your pet will enjoy a stress-free experience with no travel, no cages, and no waiting. We use natural products and provide personalized care in our fully equipped van.</p>
						<br/>
						<p>The grooming session takes approximately 1.5 - 2 hours, depending on your dog's coat. We just need a parking space for our van and your furry friend will be pampered in no time!</p>
					</div>
					<img src="resources/van.png" alt="Inspired Canine Grooming Van"/>
				</div>
				<div className="centralCont aboutWrap">
					<h3>Book now! <a href="tel:07779121111">0777 912 1111</a></h3>
					<br/>
				</div>
				<br/><br/>
			</motion.section>
		</>
	)
};

export default About;
