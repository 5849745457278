import React, { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const recaptchaRef = useRef(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === 'email') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setIsEmailValid(emailPattern.test(e.target.value));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = await recaptchaRef.current.executeAsync();

        if (!token) {
            alert('Please complete the reCAPTCHA.');
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch('/php/contact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData, recaptchaToken: token }),
            });

            if (response.ok) {
                setIsSubmitted(true);
                setFormData({ name: '', email: '', message: '' });
                recaptchaRef.current.reset(); // Reset reCAPTCHA
            } else {
                console.error('Form submission failed');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
			<div className="container">
				<h2>Contact Us</h2>
				<p>
					Have a question or want to book an appointment?<br />
					Get in touch below or gives us a call on <a href="tel:07779121111">0777 912 1111</a>!
				</p>
				<br />

				{isSubmitted ? (
					<div className="success-message">Message sent successfully!</div>
				) : (
					<form onSubmit={handleSubmit}>
						<label htmlFor="name">Name:</label>
						<input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
						<label htmlFor="email">Email:</label>
						<input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
						{!isEmailValid && <div className="error-message">Please enter a valid email address.</div>}
						<label htmlFor="message">Message:</label>
						<textarea id="message" name="message" value={formData.message} onChange={handleChange} required />
						<ReCAPTCHA ref={recaptchaRef} sitekey="6Lc5UJUqAAAAAOOcd40_WtRCMbCSzr3vs1L1M36K" size="invisible" />
						<button type="submit" disabled={isLoading || !isEmailValid}>
							{isLoading ? 'Sending...' : 'Send Message'}
						</button>
					</form>
				)}
			</div>
        </>
    );
};

export default ContactForm;