import { Outlet } from "react-router-dom";
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const Layout = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<>
			<div className="heightHack">
				<motion.header
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 1, ease: "easeInOut" }}
				>
					<a href="/" className="logo"><h1>Inspired Canine Grooming</h1></a>
					<nav>
						<div className="nav-links">
							<ul>
								<li><a href="/">Home</a></li>
								<li><a href="/about">About</a></li>
								<li><a href="/services">Services</a></li>
								<li><a href="/contact">Contact</a></li>
								<li><a href="https://www.facebook.com/inspiredcaninegrooming/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a></li>
							</ul>
						</div>
					</nav>
					<button className="hamburger" onClick={toggleMenu}>
						<motion.div className="bar" 
							initial={false}
							animate={isMenuOpen ? "open" : "closed"}
							variants={{
								open: { rotate: 45, translateY: 8 },
								closed: { rotate: 0, translateY: 0 }
							}}
						/>
						<motion.div className="bar"
							initial={false}
							animate={isMenuOpen ? "open" : "closed"}
							variants={{
								open: { opacity: 0 },
								closed: { opacity: 1 }
							}}
						/>
						<motion.div className="bar" 
							initial={false}
							animate={isMenuOpen ? "open" : "closed"}
							variants={{
								open: { rotate: -45, translateY: -13 },
								closed: { rotate: 0, translateY: 0 }
							}}
						/>
					</button>
					<nav className={`hamburgerMenu ${isMenuOpen ? 'open' : ''}`}>
						<div>
							<ul>
								<li><a href="/">Home</a></li>
								<li><a href="/about">About</a></li>
								<li><a href="/services">Services</a></li>
								<li><a href="/contact">Contact</a></li>
								<li><a href="https://www.facebook.com/inspiredcaninegrooming/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a></li>
							</ul>
						</div>
					</nav>
				</motion.header>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 1, ease: "easeInOut" }}
				>
					<Outlet />
				</motion.div>
			</div>
			<motion.footer
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 1, ease: "easeInOut" }}
			>
				<a href="/">Home</a> - 
				<a href="/about">About</a> - 
				<a href="/services">Services</a> - 
				<a href="/contact">Contact</a> - 
				<a href="https://www.facebook.com/inspiredcaninegrooming/" target="_blank" rel="noreferrer">Facebook</a>
				<p>&copy; 2024 Inspired Canine Grooming</p>
			</motion.footer>
		</>
	);
};

export default Layout;