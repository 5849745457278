import React from 'react'; 
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const Services = () => {
	
	return(
		<>
			<Helmet>
				<title>Dog Grooming Services Fareham | Inspired Canine Grooming</title>
				<meta 
					name="description" 
					content="Full grooming, bath & brush, puppy grooms & more! Inspired Canine Grooming offers expert mobile dog grooming services in Fareham, Hampshire.  View our services & prices." 
				/>
			</Helmet>
			<motion.section
				whileInView={{ y: 0, opacity: 1, scale: 1 }}
				initial={{ y: 50, opacity: 0, scale: 0.5 }}
				transition={{ duration: 0.4 }}
				id="services"
				className="services"
			>
				<div className="centralCont">
				<h2>Our Services</h2>
				<div className="container">
					<div className="service">
						<img src="resources/dog1.png" alt="Inspired Canine Grooming dog 1"/>
					</div>
					<div className="service">
						<img src="resources/inVan.png" alt="Inspired Canine Grooming Inside Van"/>
					</div>
					<div className="service">
						<img src="resources/dog2.png" alt="Inspired Canine Grooming dog 2"/>
					</div>
				</div>
				<p>Experience professional grooming in the comfort of your own driveway! Our fully equipped van is staffed by a City & Guilds qualified groomer who will provide personalized care for your dog.</p>
				<br/>
				<p>The mobile van is equipped with state-of-the-art amenities, including its own power source and heating system. This means you won't be inconvenienced by my need to use your electricity, and your companion will stay comfortably warm even on the coldest winter days.</p>
				<br/>
				<p>We begin with a thorough health check to assess your dog's needs and discuss your desired style. In cases of severe matting or parasite issues, we may need to reschedule or perform an initial "tidy-up" groom to prepare your dog's coat for the full treatment.</p>
				<br/>
				<p>To keep your dog's coat looking its best, we recommend regular brushing between appointments.</p>
				<br/>
				<h3>Book now! <a href="tel:07779121111">0777 912 1111</a></h3>
				<br/>
				</div>
			</motion.section>
		</>
	)
};

export default Services;
