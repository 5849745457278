import React from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import ContactForm from "./ContactForm";

const Contact = () => {

    return (
        <>
            <Helmet>
                <title>
                    Contact Inspired Canine Grooming | Mobile Dog Grooming Fareham
                </title>
                <meta
                    name="description"
                    content="Get in touch with Inspired Canine Grooming to book your mobile dog grooming appointment in Fareham, Hampshire. Call us, email us, or use our online booking form."
                />
            </Helmet>
            <motion.section
                whileInView={{ y: 0, opacity: 1, scale: 1 }}
                initial={{ y: 50, opacity: 0, scale: 0.5 }}
                transition={{ duration: 0.4 }}
                id="contact"
                className="contact"
            >
				<ContactForm />
            </motion.section>
        </>
    );
};

export default Contact;